import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/agent/AgentIndex.vue')
  },

  /** 矩阵开始 */
  {
    path: '/matrix',
    name: 'matrix',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/matrix/HomePage.vue')
  },
  {
    path: '/t3',
    name: 't3',
    component: () => import('../views/matrix/T3Pool.vue')
  },
  {
    path: '/t6',
    name: 't6',
    component: () => import('../views/matrix/T6Pool.vue')
  },
  {
    path: '/PoolLog',
    name: 'PoolLog',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/matrix/PoolLog.vue')
  },
  {
    path: '/Rules',
    name: 'Rules',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/matrix/RulesPage.vue')
  },
  /** 矩阵结束 */

  // 商城
  {
    path: '/shop',
    name: 'shop',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/shop/ShopIndex.vue')
  },

  // 商城规则说明
  {
    path: '/shop/rules',
    name: 'rules',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/shop/rules/RulesIndex.vue')
  },

  // 商城分类
  {
    path: '/shop/category',
    name: 'category',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/shop/category/CategoryList.vue')
  },
  // 商城商品列表
  {
    path: '/shop/goodsList',
    name: 'goodsList',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/shop/goods/GoodsList.vue')
  },
  // 商城商品详情
  {
    path: '/shop/goodsDetail',
    name: 'goodsDetail',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/shop/goods/GoodsDetail.vue')
  },
  // 商城添加商品
  {
    path: '/shop/goodsAdd',
    name: 'goodsAdd',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/shop/goods/GoodsAdd.vue')
  },
  // 商城订单确认页
  {
    path: '/shop/confirm',
    name: 'confirm',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/shop/order/OrderConfirm.vue')
  },
  // 商城订单支付页
  {
    path: '/shop/payOrder',
    name: 'payOrder',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/shop/order/OrderPay.vue')
  },
  // 商城我的订单列表
  {
    path: '/shop/orderList',
    name: 'orderList',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/shop/order/OrderList.vue')
  },
  // 商城我的订单详情
  {
    path: '/shop/orderDeatil',
    name: 'orderDeatil',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/shop/order/OrderDetail.vue')
  },
  // 商城我的收货地址
  {
    path: '/shop/addressList',
    name: 'addressList',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/shop/address/AddressList.vue')
  },
  // 商城收货地址添加编辑
  {
    path: '/shop/addressSave',
    name: 'addressSave',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/shop/address/AddressSave.vue')
  },
  // 商城商户商品列表
  {
    path: '/shop/sellerGoods',
    name: 'sellerGoods',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/shop/seller/goods/GoodsList.vue')
  },

  // 商城商户订单列表
  {
    path: '/shop/sellerOrder',
    name: 'sellerOrder',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/shop/seller/order/OrderList.vue')
  },

  // TRS币记录
  {
    path: '/shop/trsLog',
    name: 'trsLog',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/shop/trs/TrsLog.vue')
  },

  // TRS币记录
  {
    path: '/shop/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/shop/goods/searchRet.vue')
  },

  // 链长订单
  {
    path: '/shop/linkOrder',
    name: 'linkOrder',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/shop/order/LinkOrder.vue')
  },

  // 个人中心首页
  {
    path: '/userCenter',
    name: 'userCenter',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/user/UserCenter.vue')
  },

  // 我的团队
  {
    path: '/team',
    name: 'team',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/user/MyTeam.vue')
  },

  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template>
  <van-config-provider theme="dark">
    <div class="app-main">
      <router-view />
    </div>
    <footer-tab></footer-tab>
  </van-config-provider>
</template>
<script>
import FooterTab from '@/components/FooterTab.vue';
import { ConfigProvider, showToast } from 'vant';
import { provide, ref, onMounted } from 'vue';
import{ createUser } from '@/network/api';
import "vant/lib/index.css"; // 导入 Vant 样式文件
import "@/assets/fonts/iconfont.css"  //导入图标样式
import tp from "tp-js-sdk"; //导入tp-js-sdk
export default {


  setup() {



    // 创建用户
    // const register = function (wallet) {
    //   createUser({
    //     wallet: wallet,
    //     p_code: sessionStorage.getItem("p_code"),
    //   })
    //     .then((response) => {
    //       const res = response.data;
    //       if (res.code != 1) {
    //         showToast({
    //           message: res.msg,
    //           position: 'bottom',
    //           className: 'base-show-toast base-error-toast'
    //         })
    //       } else {
    //         localStorage.setItem('token', res.data.token.token);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // };

    // 异步方法 authorize 使用 async/await 语法
    const authorize = async () => {
      try {
        // 使用 TokenPocket 提供的 tp-js-sdk 获取钱包信息
        const res = await tp.getCurrentWallet();
        console.log(res,'tp');
        if (res.result && res.data.blockchain == 'matic') {
          register(res.data.address);
        } else {
          showToast({
            message: '获取钱包失败',
            position: 'bottom',
            className: 'base-show-toast base-error-toast'
          });
        }
      } catch (error) {
        console.log(error,'error');
        showToast({
          message: '获取钱包失败',
          position: 'bottom',
          className: 'base-show-toast base-error-toast'
        });
      }
    };

    onMounted(() => {
      if (!window.localStorage.getItem("token")) {
        // 获取用户信息，如果没有就自动注册
        // authorize();

      }
    });
    return {
      authorize,
      // register,
    }
  },
  components: {
    FooterTab,
    "van-config-provider": ConfigProvider,
  }
}
</script>
<style>
.app-main {
  max-width: 800px;
  margin: 0 auto 3.125rem;
}

img {
  max-width: 100%;
}

.base-bg {
  background-color: var(--base-bg-color) !important;
  /* color: var(--chakra-colors-secondaryGray-600) !important; */
}

.van-cell {
  background-color: var(--base-bg-color) !important;
}

.van-action-bar-button--danger,
.van-submit-bar__button--danger,
.van-button--primary,
.van-switch--on {
  background: var(--chakra-colors-brand-400) !important;
  color: var(--chakra-colors-whiteAlpha-900) !important;
  border: none !important;
}

.van-button--default {
  /* background-color: var(--base-bg-color) !important; */
  /* border: none !important; */
}

@media only screen and (min-width: 800px) {}
</style>

<style lang="scss" scoped>
@import '@/assets/css/base.css';

* {
  box-sizing: border-box;
}

:global(.base-show-toast) {
  width: 100vw !important;
  min-height: 1.5rem !important;
  line-height: 1.5rem !important;
  font-size: 1rem !important;
  background: #bee3f8 !important;
  color: #171923 !important;
  bottom: 1.5rem !important;
}

:global(.base-error-toast) {
  background: #FEB2B2 !important;
}

.footer-nav {
  display: flex;
  justify-content: space-around;
  text-align: center;
  background-color: #f6f6f6;
  height: 3.125rem;
  line-height: 1.5625rem;
  font-size: var(--font-size);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 -3px 1px rgba(100, 100, 100, 0.1);
}

.main {
  margin-top: 2.8125rem;
  margin-bottom: 3.125rem;
}

.footer-nav a {
  // display: inline-block;
  flex: 1;

  &.router-link-exact-active {
    color: var(--color-high-text);
  }
}

.footer-nav .active {
  color: var(--color-high-text);
}

.iconfont {
  font-size: 1.375rem;
}</style>


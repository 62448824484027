<template>
  <Tabbar v-model="active" route>
    <TabbarItem replace to="/shop" icon="home-o">商城</TabbarItem>
    <TabbarItem replace to="/" icon="points">代理</TabbarItem>
    <TabbarItem replace to="/userCenter" icon="contact-o">我的</TabbarItem>
  </Tabbar>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Tabbar, TabbarItem } from 'vant';
import { useRoute } from 'vue-router';

const route = useRoute();
const active = ref('');

onMounted(() => {
  active.value = route.path;
});
</script>
<style lang="scss" scoped>
.van-tabbar-item--active{
  color: var(--chakra-colors-brand-400);
}
</style>

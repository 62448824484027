import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 创建应用实例
const app = createApp(App);

// 添加全局变量
app.config.globalProperties.$baseUrl = 'https://api.terracecoin.com';
// app.config.globalProperties.$baseUrl = 'http://www.trs.com';

// 使用插件和挂载
app.use(store).use(router).mount('#app');


// createApp(App).use(store).use(router).mount('#app')
